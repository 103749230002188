@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"] {
  padding: 0.5rem;
  -webkit-appearance: none !important;
  appearance: none;
}

textarea {
  padding: 0.5rem;
  -webkit-appearance: none !important;
  appearance: none;
}

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
.loader-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f3ff;
  color: black;
}

.day-circle-selected {
  background-color: #3b82f6;
  color: white;
}

.capitalized {
  text-transform: capitalize;
}

.day-time-container {
  width: 140px;
  height: min-content;
}
.slot-box {
  position: relative;
  width: 140px;
  height: min-content;
  box-shadow: 0 0 0 1px #dfdfdf;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: black;
}

.slot-box-active {
  position: relative;
  width: 140px;
  height: min-content;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #3b82f6;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3b82f6;
  color: white;
}

.slot-box-not-available {
  width: 140px;
  height: min-content;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #dfdfdf;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  color: black;
}

.discount-strip {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff0000;
  color: #ffffff;
  padding: 0px 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 1px;
  font-size: 9px;
  box-shadow: 0 0 0 1px #ff0000;
}

/* Custom calendar start */
.react-calendar {
  width: auto !important;
  max-width: 100%;
  background-color: #fff;

  color: black;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation span {
  text-transform: capitalize;
  font-weight: 600;
}
.react-calendar__navigation button {
  color: #082f49;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr {
  text-decoration: none;
  text-transform: capitalize;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

.react-calendar__tile {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #082f49;
  border-radius: 6px;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--now {
  background: #082f494b !important;
  color: white;
  border-radius: 6px;
}

.react-calendar__tile--now:enabled:hover {
  background: #082f494b !important;
  color: white;
}

.react-calendar__tile--now:enabled:focus {
  background: #082f49 !important;
  border-radius: 6px;
  color: white;
}
.react-calendar__tile--active {
  background: #082f49 !important;
  border-radius: 6px !important;
  font-weight: bold;
  color: white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #082f49 !important;
  color: white;
  border-radius: 6px !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #082f49;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #082f49;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #082f49;
  color: white;
}
/* Custom calendar end */

.parallax-section {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
  will-change: transform;
  background-image: url("images/clean-browser-mockup-mobile.png");
}
.strike-center {
  position: relative;
  white-space: nowrap; /* would center line-through in the middle of the wrapped lines */
}

.strike-center:after {
  border-top: 2px solid #000;
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  left: 0;
}
.bg-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 700px;
}

.analytics-chart {
  color: #fff;
  background-color: rgb(255, 255, 255);
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 400px;
}
@media only screen and (min-width: 1200px) {
  .parallax-section {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transform: translateZ(0);
    will-change: transform;
    background-image: url("images/clean-browser-mockup.png");
  }
}
